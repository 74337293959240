import React from "react";

const Faq = () => {
  return (
    <section id="faqs" className="faq-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="section-title mb-45">
              <h3 className="title">Ce en QUI nous Croyons</h3>
            </div>
          </div>
        </div>
        <div className="faq-box">
          <div className="row">
            <div className="col-lg-8">
              <div className="faq-accrodion">
                <div className="accrodion">
                  <div className="inner">
                    <p>
                      Nous croyons en Dieu créateur de tout l'Univers et en sa
                      puissante et infaillible parole ; c'est par elle que Dieu
                      a créé toute chose. Nous croyons que Jésus Christ est le
                      fils unique de Dieu, lui-même Dieu. Il a été conçu par le
                      Saint Esprit et est né d'une vierge. Nous croyons que
                      Jésus Christ est sans péché et qu'il a été accusé
                      injustement et mis à mort selon le plan de Dieu. Nous
                      croyons qu'il est mort, mais qu'il est ressuscité le 3ème
                      jour, qu'il est monté au ciel et s'est assis à la droite
                      de Dieu le Père. Nous croyons au Saint Esprit comme étant
                      la troisième personne de la trinité. Nous croyons que
                      Jésus Christ revient pour chercher son église, et juger le
                      monde. Nous croyons à l'œuvre de Christ accomplie à la
                      croix, à la rémission des péchés et à la vie éternelle.
                      Nous croyons que par sa mort Jésus a payé le prix qui
                      donne à tout Homme d'être réconcilié avec Dieu.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="faq-shape"></div> */}
    </section>
  );
};

export default Faq;
