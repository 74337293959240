import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import BlogItem from "../components/BlogItem";
import Icon from "../components/common/Icon";
import axios from "axios";
import { useState, useEffect } from "react";

const Blog = () => {
  const [dataPost, setDataPost] = useState([]);

  useEffect(() => {
    axios
      .get("/api/posts")
      .then((res) => {
        const data = res?.data?.["hydra:member"];
        if (data?.length > 0) {
          setDataPost(data.reverse());
        }
      })
      .catch((e) => {
        console.error(e);
      });
  }, []);

  return (
    <section id="blog" className="news-area">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-10 col-md-10">
            <div className="section-title text-center">
              <h3 className="title">
                En savoir plus sur nos activités
                <br />
                depuis notre blog.
              </h3>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          {dataPost
            .filter((_, idx) => idx < 3)
            .map((post) => (
              <BlogItem key={post?.id} data={post} />
            ))}

          <div className="col-lg-12">
            <div className="news-btn text-center mt-60">
              <Link className="main-btn" to="/blog">
                Visiter notre blog <Icon icon={faAngleRight} />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Blog;
