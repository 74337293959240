import React from "react";

const Autres = () => {
  return (
    <div className="paymentItem">
      <div className="paymentItemImg">
        <img src="/images/logos/dons.jpg" alt="Autres" />
      </div>
      <div className="paymentContent">
        <p>Pour des dons matériels ou autres, veuillez nous contacter</p>
        <h2>
          <span>Fixe: </span> +225 27 35 95 81 13
        </h2>
        <h2>
          <span>Mobile: </span> +225 07 99 13 20 13
        </h2>
        <h2>
          <span>Whatsapp: </span> +225 05 45 45 26 45
        </h2>
        <h2>
          <span>Email: </span> hami042016@gmail.com
        </h2>
      </div>
    </div>
  );
};

export default Autres;
