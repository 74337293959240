import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";

const BlogItem = ({ data }) => {
  if (!data) return;

  const { title, description, date, slug, thumbnail } = data || {};
  return (
    <div className="col-lg-4 col-md-7 col-sm-9">
      <div className="news-item mt-30">
        <div className="news-thumb">
          <img
            src={`${process.env.REACT_APP_API_URL}/images/${thumbnail}`}
            alt={title}
          />
        </div>
        <div className="news-content">
          <Link to={`/blog/${slug}`}>
            <h4 className="title">{title}</h4>
          </Link>
          <br />
          <span>{moment(date).format("DD MMM YYYY")}</span>
          <p>{description}</p>
        </div>
      </div>
    </div>
  );
};

export default BlogItem;
