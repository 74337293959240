import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import Icon from "../components/common/Icon";

const Testimonial = () => {
  return (
    <>
      <section id="feedbacks" className="testimonial-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="section-title">
                <h3 className="title">Pasteur Leader</h3>
              </div>
            </div>
          </div>
          <div className="row testimonial-active">
            <div className="col-lg-12">
              <div className="testimonial-item mt-30">
                <img
                  className="imgBrice"
                  src="/images/brice.jpg?v=2"
                  alt="Pasteur brice"
                />
                <p>
                  Le Révérend Brice NATABOU est un Serviteur de Dieu au profil
                  atypique. A l’image du prophète Jérémie dans la Bible, Il est
                  né pour servir Dieu. Même des féticheurs et des marabouts lui
                  révélaient qu’il est né pour être au service de Dieu. Après de
                  multiples paroles du Seigneur, il se consacre entièrement à ce
                  sacerdoce et suit plusieurs formations bibliques et
                  théologiques. Son amour pour Dieu, sa passion pour les âmes,
                  le poussent à obéir à l’appel de Dieu pour la mission.
                </p>
                <h5 className="title">Révérend Brice NATABOU</h5>
                {/* <span>USA</span> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="download-area">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="section-title">
                <h3 className="title">En savoir plus à propos de nous.</h3>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="download-btns">
                <ul className="nav">
                  <li>
                    <Link className="main-btn" to="/eglise">
                      L'église <Icon icon={faAngleRight} />
                    </Link>
                  </li>
                  <li>
                    <Link className="main-btn main-btn-2" to="/a-propos">
                      Qui sommes nous? <Icon icon={faAngleRight} />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Testimonial;
