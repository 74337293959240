import Icon from "./Icon";
import { faEnvelope, faBars, faXmark } from "@fortawesome/free-solid-svg-icons";
import {
  faFacebookSquare,
  faWhatsapp,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { menuItems } from "../../lib/constants";
import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";

const Header = () => {
  const [isOpened, setIsOpened] = useState(false);

  const { pathname } = useLocation();

  const handleToggleMenu = () => setIsOpened((state) => !state);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <div
        className={`off_canvars_overlay ${isOpened ? "active" : ""}`}
        onClick={handleToggleMenu}
      />
      <div className="offcanvas_menu">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div
                className={`offcanvas_menu_wrapper ${isOpened ? "active" : ""}`}
              >
                <div className="canvas_close" onClick={handleToggleMenu}>
                  <a href="#">
                    <Icon icon={faXmark} />
                  </a>
                </div>
                <div className="offcanvas-social">
                  <ul className="text-center">
                    <li>
                      <a
                        href="https://www.facebook.com/EEACTempledelaVictoire/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Icon icon={faFacebookSquare} />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.youtube.com/channel/UCKStvoBGL2-i7LAZbR9VXng"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Icon icon={faYoutube} />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://wa.me/+2250545452645"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Icon icon={faWhatsapp} />
                      </a>
                    </li>
                    <li>
                      <a
                        href="mailto:hami042016@gmail.com"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Icon icon={faEnvelope} />
                      </a>
                    </li>
                  </ul>
                </div>
                <div id="menu" className="text-left ">
                  <ul className="offcanvas_main_menu">
                    {menuItems.map((menu) => (
                      <li
                        key={menu.id}
                        className={`menu-item-has-children ${
                          pathname === menu.url ? "active" : ""
                        }`}
                        onClick={handleToggleMenu}
                      >
                        <Link to={menu.url}>{menu.label}</Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <header id="home" className="header-area header-v1-area">
        <div className="header-nav">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="navigation">
                  <nav className="navbar navbar-expand-lg navbar-light ">
                    <Link className="navbar-brand logo" to="/">
                      <img src="/images/logo.png" alt="logo eeac" />
                    </Link>
                    <span
                      className="side-menu__toggler"
                      onClick={handleToggleMenu}
                    >
                      <Icon icon={faBars} />
                    </span>

                    <div
                      className="collapse navbar-collapse sub-menu-bar main-nav__main-navigation"
                      id="navbarSupportedContent"
                    >
                      <ul className="navbar-nav ml-auto main-nav__navigation-box">
                        {menuItems.map((menu) => (
                          <li
                            key={menu.id}
                            className={`nav-item ${
                              pathname === menu.url ? "active" : ""
                            }`}
                          >
                            <Link className="nav-link" to={menu.url}>
                              {menu.label}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
