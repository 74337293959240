import "./styles/style.scss";

import { Routes, Route } from "react-router-dom";
import Header from "./components/common/Header";
import Footer from "./components/common/Footer";

import Home from "./pages/Home";
import Apropos from "./pages/Apropos";
import Eglise from "./pages/Eglise";
import Vision from "./pages/Vision";
import Blog from "./pages/Blog";
import Conference from "./pages/Conference";
import BlogDetail from "./pages/BlogDetail";
import Page404 from "./pages/Page404";
// import Don from "./pages/Don";

const App = () => {
  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="a-propos" element={<Apropos />} />
        <Route path="eglise" element={<Eglise />} />
        <Route path="vision" element={<Vision />} />
        <Route path="conference" element={<Conference />} />
        <Route path="blog" element={<Blog />} />
        <Route path="blog/:slug" element={<BlogDetail />} />
        {/* <Route path="don" element={<Don />} /> */}
        <Route path="*" element={<Page404 />} />
      </Routes>
      <Footer />
    </>
  );
};

export default App;
