import React from "react";
import Donation from "../sections/Donation";
import PageTitle from "../sections/PageTitle";
import axios from "axios";
import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import moment from "moment";
import ReactHtmlParser from "react-html-parser";

const BlogDetail = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [dataPost, setDataPost] = useState({});
  const [numberOfImages, setNumberOfImages] = useState(0);
  const { slug } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!slug) navigate("/blog");
    setIsLoading(true);

    axios
      .get(`/api/posts?slug=${slug}`)
      .then((res) => {
        const data = res?.data?.["hydra:member"]?.[0] || {};
        setDataPost(data);
        let imgs = 0;
        if (data?.image1) imgs++;
        if (data?.image2) imgs++;
        if (data?.image3) imgs++;

        setNumberOfImages(imgs);

        setIsLoading(false);
      })
      .catch((e) => {
        console.error(e);
        setIsLoading(false);
      });
  }, [slug, navigate]);

  const getColumnLeft = () => {
    if (numberOfImages === 3) return "col-lg-4";
    if (numberOfImages === 2) return "col-lg-6";
    if (numberOfImages === 1) return "col-lg-12";
  };
  const getColumnRight = () => {
    if (numberOfImages === 3) return "col-lg-8";
    if (numberOfImages === 2) return "col-lg-6";
  };

  return (
    <>
      <PageTitle
        title="Blog"
        page={isLoading ? "..." : dataPost?.title}
        hasSubpage
        subpage={{ url: "/blog", label: "Blog d'EEAC" }}
      />
      {!isLoading && (
        <section className="blog-area single-blog-area pt-120 pb-120">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="blog-thumb">
                  <img
                    src={`${process.env.REACT_APP_API_URL}/images/${dataPost?.thumbnail}`}
                    alt={dataPost?.title}
                  />
                </div>
                <div className="row justify-content-center">
                  <div className="col-lg-8">
                    <div className="single-blog-top-content">
                      <h2 className="title">{dataPost?.title}</h2>
                      <span>
                        {moment(dataPost?.date).format("DD MMM YYYY")}
                      </span>
                      {ReactHtmlParser(dataPost?.content || "")}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className={getColumnLeft()}>
                    {numberOfImages > 0 && (
                      <div className="blog-thumb mt-30">
                        <img
                          src={`${process.env.REACT_APP_API_URL}/images/${dataPost?.image1}`}
                          alt=""
                        />
                      </div>
                    )}
                    {numberOfImages === 3 && (
                      <div className="blog-thumb mt-30">
                        <img
                          src={`${process.env.REACT_APP_API_URL}/images/${dataPost?.image2}`}
                          alt=""
                        />
                      </div>
                    )}
                  </div>
                  {numberOfImages >= 2 && (
                    <div className={getColumnRight()}>
                      <div className="blog-thumb mt-30">
                        <img
                          src={`${process.env.REACT_APP_API_URL}/images/${
                            numberOfImages === 3
                              ? dataPost?.image3
                              : dataPost?.image2
                          }`}
                          alt=""
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      <Donation smallBg />
    </>
  );
};

export default BlogDetail;
