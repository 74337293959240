import React from "react";

// BOA

// Nom: NATABOU YEBOU BRICE MAHOUGNON
// Code Banque: CI032
// Code Guichet: 01020
// Numéro de Compte: 009277580005
// Cle RIB: 92
// IBAN: CI93 CI03 2010 2000 9277 5800 0592
// SWIFT: AFRICABXXX

// SG
// Nom: NATABOU YEBOU BRICE MAHOUGNON
// Code Banque: CI008
// Code Guichet: 13421
// Numéro de Compte: 0421406724-45
// Cle RIB: 08

const Virement = () => {
  return (
    <>
      <div className="paymentItem">
        <div className="paymentItemImg">
          <img src="/images/logos/boa.jpg" alt="BOA" />
        </div>
        <div className="paymentContent">
          <p>BOA</p>
          <h2>
            <span>Nom: </span> NATABOU
          </h2>
          <h2>
            <span>Prénom: </span> YÉBOU BRICE MAHOUGNON
          </h2>
          <h2>
            <span>Code Banque: </span> CI032
          </h2>
          <h2>
            <span>Code Guichet: </span> 01020
          </h2>
          <h2>
            <span>Numéro de Compte: </span> 009277580005
          </h2>
          <h2>
            <span>Cle RIB: </span> 92
          </h2>
          <h2>
            <span>IBAN: </span> CI93 CI03 2010 2000 9277 5800 0592
          </h2>
          <h2>
            <span>SWIFT: </span> AFRICABXXX
          </h2>
        </div>
      </div>
      <div className="paymentItem">
        <div className="paymentItemImg">
          <img src="/images/logos/sg.jpg" alt="Société Générale" />
        </div>
        <div className="paymentContent">
          <p>Société Générale</p>
          <h2>
            <span>Nom: </span> NATABOU
          </h2>
          <h2>
            <span>Prénom: </span> YÉBOU BRICE MAHOUGNON
          </h2>
          <h2>
            <span>Code Banque: </span> CI008
          </h2>
          <h2>
            <span>Code Guichet: </span> 13421
          </h2>
          <h2>
            <span>Numéro de Compte: </span> 0421406724-45
          </h2>
          <h2>
            <span>Cle RIB: </span> 08
          </h2>
          <h2>
            <span>IBAN: </span> CI93
          </h2>
          <h2>
            <span>SWIFT: </span> SGCICIAB
          </h2>
        </div>
      </div>
    </>
  );
};

export default Virement;
