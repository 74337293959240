import React from "react";

const Capture = () => {
  return (
    <section className="capture-area ">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="section-title mt-60 mb-30">
              <h3 className="title">Présentation de l'église</h3>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="col-12">
          <div className="capture-active">
            <div className="capture-item mt-30">
              <div className="capture-thumb">
                <img src="/images/about.jpg" alt="" />
              </div>
              <div className="capture-content">
                <h4 className="title">
                  L'église Évangélique de l'Armée Céleste - E.E.A.C
                </h4>
                <p>
                  Le Temple de la Victoire, La Synagogue des Nations est basée à
                  Abidjan en Côte d'Ivoire. C'est une église
                  interdénominationelle a commencé en 2016 par le Révérend Brice
                  NATABOU. Elle a un réel impact sur la communauté et dans le
                  monde entier. C'est une église où la parole révélée de Dieu
                  est puissamment enseignée et la présence de Dieu tangible et
                  réelle. Les témoignages de guérison, restauration et surtout
                  de changement de mentalité sont légion. Il est impossible
                  d'être membre de cette assemblée sans désirer s'équiper pour
                  accomplir, avec tous les moyens utiles, sa destinée.
                </p>
                <p>
                  Le Temple de la Victoire, La Synagogue des Nations se veut
                  donc un instrument entre les mains de Dieu pour équiper et
                  encourager chaque personne créée à l'image et selon la
                  ressemblance de Dieu à accomplir ce pour quoi (le mandat) Dieu
                  l'a suscitée sur terre et être un héros de Dieu dans sa
                  génération.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Capture;
