import { Fragment } from "react";
import Icon from "../components/common/Icon";

const Intro = ({
  secondBg = false,
  hasImage = false,
  hasPadding = false,
  image = "",
  title = "",
  data = [],
}) => {
  if (!data.length > 0) return;

  const compileText = (data = []) => {
    const textCompiled = data.map((item) => {
      const { id, type, text } = item;

      if (type === "span") return { id, content: <span key={id}>{text}</span> };
      return { id, content: <Fragment key={id}>{text}</Fragment> };
    });
    return (
      <>
        {textCompiled.map((item) => {
          return <Fragment key={item.id}>{item.content} </Fragment>;
        })}
      </>
    );
  };
  return (
    <section
      id="features"
      className={`intro-area ${secondBg ? "secondBg" : ""} ${
        hasPadding ? "hasPadding" : ""
      }`}
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-7">
            <div className="section-title text-center">
              <h3 className="title">{title}</h3>
            </div>
          </div>
        </div>
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="intro-thumb">
              {hasImage && <img src={image} alt="" />}
            </div>
          </div>
          <div className="col-lg-6">
            <div className="intro-content">
              {data.map((item) => {
                const { title, icon, content, id } = item;
                return (
                  <div key={id} className="intro-item mb-30">
                    <Icon icon={icon} />
                    <h4 className="title">{title}</h4>
                    {content.map((contentItem) => {
                      const { content: paragraphs, id: idContent } =
                        contentItem;
                      return <p key={idContent}>{compileText(paragraphs)}</p>;
                    })}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Intro;
