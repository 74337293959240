import Donation from "../sections/Donation";
import PageTitle from "../sections/PageTitle";
import Faq from "../sections/Faq";
import Capture from "../sections/Capture";

const Eglise = () => {
  return (
    <>
      <PageTitle title="L'Église" page="L'Église" />
      <Capture />
      <Faq />
      <Donation smallBg />
    </>
  );
};

export default Eglise;
