import Donation from "../sections/Donation";
import Intro from "../sections/Intro";
import PageTitle from "../sections/PageTitle";
import { faBookBible } from "@fortawesome/free-solid-svg-icons";

const introData = [
  {
    id: 1,
    title: "L'ÉGLISE ÉVANGELIQUE DE L'ARMEE CELESTE - EEAC",
    icon: faBookBible,
    content: [
      {
        id: 1,
        content: [
          {
            id: 1,
            type: "text",
            text: "C'est un ministère interdénominationnel à caractère prophétique, apostolique, et évangélique fondé sur l'enseignement de la parole de Dieu. Créé par le ",
          },
          {
            id: 2,
            type: "span",
            text: "Révérend Brice NATABOU",
          },
          {
            id: 3,
            type: "text",
            text: " , lui-même suscité et envoyé par Dieu en Côte d'Ivoire, où il arriva en fin d'année 2019. Sa foi, son engagement et sa détermination dans l'œuvre ont impacté et continuent d'impacter considérablement de nombreuses vies, tant en Côte d'Ivoire que dans plusieurs autres nations du monde.",
          },
        ],
      },
    ],
  },
  {
    id: 2,
    title: "",
    icon: faBookBible,
    content: [
      {
        id: 1,
        content: [
          {
            id: 1,
            type: "text",
            text: "La Bible qui est la parole infaillible de Dieu déclare en ",
          },
          {
            id: 2,
            type: "span",
            text: "Jean 1 : 1 “Au commencement était la Parole, et la Parole était avec Dieu, et la Parole était Dieu”.",
          },
        ],
      },
    ],
  },
  {
    id: 3,
    title: "",
    icon: faBookBible,
    content: [
      {
        id: 1,
        content: [
          {
            id: 1,
            type: "text",
            text: "Dieu manifestement se révèle au travers de sa parole; car il est dans sa parole et il est sa parole. Et, c'est seulement par elle que l'on peut parvenir à sa réelle connaissance ! Notre prière est que Dieu accorde à toute personne visitant ce site ",
          },
          {
            id: 2,
            type: "span",
            text: "“un esprit de sagesse et de révélation dans sa connaissance; qu'il illumine les yeux de son cœur” Éphésiens. 1 :17-18",
          },
          {
            id: 3,
            type: "text",
            text: ", afin que par la connaissance de la vérité (de sa parole), elle soit affranchie. Et que, habitée par la capacité surnaturelle de Dieu, elle accomplisse sa destinée.",
          },
        ],
      },
    ],
  },
];

const Apropos = () => {
  return (
    <>
      <PageTitle title="À Propos" page="À Propos d'EEAC" />
      <Intro title="Qui Sommes Nous?" secondBg hasPadding data={introData} />
      <Donation smallBg />
    </>
  );
};

export default Apropos;
