import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import Icon from "../components/common/Icon";
import { useState } from "react";
import MobileMoney from "./MobileMoney";
import Virement from "./Virement";
import Autres from "./Autres";
import Transfert from "./Transfert";

const Donation = ({ smallBg = false }) => {
  const [shownSection, setShownSection] = useState("");

  const showSection = (section) => {
    section === shownSection ? setShownSection("") : setShownSection(section);
  };

  return (
    <section
      id="don"
      className={`download-app-area  ${smallBg ? "smallBg" : ""}`}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            <div className="download-app-content">
              <h3 className="title">Dîme et dons de charité</h3>
              <p>
                Vous êtes désormais <span>partenaire financier de Dieu </span>{" "}
                pour l'extension et l'établissement de son règne sur la terre.
              </p>
              <ul className="nav">
                <li className="mt-20">
                  <button
                    onClick={() => showSection("mobile")}
                    className={`main-btn main-btn-3 ${
                      shownSection === "mobile" ? "main-btn-5" : ""
                    }`}
                  >
                    Mobile Money <Icon icon={faAngleRight} />
                  </button>
                </li>
                <li className="mt-20">
                  <button
                    onClick={() => showSection("virement")}
                    className={`main-btn main-btn-2 main-btn-4 ${
                      shownSection === "virement" ? "main-btn-5" : ""
                    }`}
                  >
                    Virement Bancaire <Icon icon={faAngleRight} />
                  </button>
                </li>
                <li className="mt-20">
                  <button
                    onClick={() => showSection("transfert")}
                    className={`main-btn main-btn-2 main-btn-4 ${
                      shownSection === "transfert" ? "main-btn-5" : ""
                    }`}
                  >
                    Transfert d'Argent <Icon icon={faAngleRight} />
                  </button>
                </li>
                <li className="mt-20">
                  <button
                    onClick={() => showSection("autres")}
                    className={`main-btn  main-btn-3 ${
                      shownSection === "autres" ? "main-btn-5" : ""
                    }`}
                  >
                    Autres <Icon icon={faAngleRight} />
                  </button>
                </li>
              </ul>
            </div>
            <div className="paymentcontainer">
              {shownSection === "mobile" && <MobileMoney />}
              {shownSection === "virement" && <Virement />}
              {shownSection === "transfert" && <Transfert />}
              {shownSection === "autres" && <Autres />}
            </div>
          </div>
        </div>
      </div>
      <div className="download-app-case">
        <img src="/images/download-app-case.png" alt="" />
      </div>
    </section>
  );
};

export default Donation;
