import React from "react";
import { Link } from "react-router-dom";
import Icon from "./Icon";
import {
  faEnvelope,
  faAngleUp,
  faLocationDot,
  faPhone,
  faMobileRetro,
} from "@fortawesome/free-solid-svg-icons";

import {
  faFacebookSquare,
  faWhatsapp,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

import { menuItems } from "../../lib/constants.js";

const Footer = () => {
  return (
    <>
      <section
        className="footer-area bg_cover"
        style={{ backgroundImage: 'url("/images/hero-bg2.jpg")' }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="footer-about mt-30">
                <Link to="/" className="logo">
                  <img src="/images/logo.png" alt="logo eeac" />
                </Link>
                <p>
                  Église Évangélique de l'Armée Céleste.Le Temple de la
                  Victoire, La Synagogue des Nations.
                </p>
                <ul>
                  <li>
                    <a
                      href="https://www.facebook.com/EEACTempledelaVictoire/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Icon icon={faFacebookSquare} />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.youtube.com/channel/UCKStvoBGL2-i7LAZbR9VXng"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Icon icon={faYoutube} />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://wa.me/+2250545452645"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Icon icon={faWhatsapp} />
                    </a>
                  </li>
                  <li>
                    <a
                      href="mailto:hami042016@gmail.com"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Icon icon={faEnvelope} />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-2 col-md-6">
              <div className="footer-list mt-40">
                <h5 className="title">Menu</h5>
                <ul>
                  {menuItems.map((menu) => (
                    <li key={menu.id}>
                      <Link to={menu.url}>{menu.label}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="footer-list mt-40">
                <h5 className="title">Contacts</h5>
                <ul>
                  <li>
                    <p>
                      <Icon icon={faLocationDot} /> Côte d'Ivoire, Adzopé
                    </p>
                  </li>
                  <li>
                    <p>
                      <Icon icon={faPhone} /> +225 27 35 95 81 13
                    </p>
                  </li>
                  <li>
                    <p>
                      <Icon icon={faMobileRetro} /> +225 07 99 13 20 13
                    </p>
                  </li>
                  <li>
                    <p>
                      <Icon icon={faWhatsapp} /> +225 05 45 45 26 45
                    </p>
                  </li>
                  <li>
                    <p>
                      <Icon icon={faEnvelope} /> hami042016@gmail.com
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="footer-list mt-40">
                <h5 className="title">Référence légale </h5>
                <ul>
                  <li>
                    <p>
                      AUTORISATION <br />
                      Nº 04/MIS/R.ME/P.ADZ/SG1 <br />
                      <span>
                        Journal Officiel N°61 du lundi 1er Août 2022 Page 849
                        République de Côte d'Ivoire.
                      </span>
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-12">
              <span className="copyright">
                © 2022 EEAC | Tous droits réservés
              </span>
            </div>
          </div>
        </div>
      </section>
      <a className="back-to-top" href="#">
        <Icon icon={faAngleUp} />
      </a>
    </>
  );
};

export default Footer;
