import React from "react";
import Icon from "../components/common/Icon";
import { faQuoteLeft } from "@fortawesome/free-solid-svg-icons";

const Features = () => {
  return (
    <section id="intro" className="features-area">
      <div className="container custom-container">
        <div className="row">
          <div className="col-lg-12">
            <div className="features-box">
              <div className="features-title">
                <h3 className="title">Mot du Leader</h3>
              </div>
              <div className="row">
                <div className="col-lg-9">
                  <div className="features-item">
                    <h4 className="title">
                      <Icon icon={faQuoteLeft} />
                      Je vous souhaite la bienvenue sur le site officiel de
                      l'Église Évangélique de l'Armée Céleste - E.E.A.C. -. A
                      travers les enseignements et les messages que nous
                      diffusons, je désire que votre mentalité soit transformée,
                      que votre vie soit révolutionnée, et que vous
                      accomplissiez certainement votre destinée.
                    </h4>
                    <p>
                      Tout ce qui est déployé au travers de ce site concourt
                      vivement à ce que vous deveniez meilleur dans la vie. Je
                      crois que Dieu a un plan pour vous ; et ce plan, il vous
                      rend capable de l'accomplir. Il faut juste y croire.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Features;
