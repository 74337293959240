export const menuItems = [
  {
    id: 1,
    label: "Acceuil",
    url: "/",
  },
  {
    id: 4,
    label: "L'église",
    url: "/eglise",
  },
  {
    id: 2,
    label: "À Propos",
    url: "/a-propos",
  },
  {
    id: 3,
    label: "Conférence",
    url: "/conference",
  },
  {
    id: 5,
    label: "Blog",
    url: "/blog",
  },
  // {
  //   id: 3,
  //   label: "Faire Un don",
  //   url: "/don",
  //   type: "btn",
  // },
];
