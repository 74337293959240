import { faBookBible } from "@fortawesome/free-solid-svg-icons";
import Hero from "../sections/Hero";
import Features from "../sections/Features";
import Intro from "../sections/Intro";
import Testimonial from "../sections/Testimonial";
import Blog from "../sections/Blog";
import Donation from "../sections/Donation";

const introData = [
  {
    id: 1,
    title: "GAGNER DES ÂMES",
    icon: faBookBible,
    content: [
      {
        id: 111,
        content: [
          {
            id: 112,
            type: "span",
            text: "Marc 16 :15",
          },
          {
            id: 212,
            type: "text",
            text: "Nous avons reçu l'ordre du Seigneur de prêcher la parole à toute la création.",
          },
        ],
      },
      {
        id: 211,
        content: [
          {
            id: 11,
            type: "span",
            text: "2 Corinthiens 5 : 17 - 20",
          },
          {
            id: 21,
            type: "text",
            text: "La raison pour laquelle Dieu nous laisse sur la terre après notre nouvelle naissance, c'est afin que nous aidions le monde à être réconcilié avec Lui.",
          },
        ],
      },
    ],
  },
  {
    id: 2,
    title: "FAIRE DES DISCIPLES",
    icon: faBookBible,
    content: [
      {
        id: 122,
        content: [
          {
            id: 12,
            type: "span",
            text: "Matthieu 28 : 18 - 20, Éphésiens 4 : 11 - 12",
          },
          {
            id: 22,
            type: "text",
            text: "Une fois converti, la mission de l'enfant de Dieu consiste aussi à faire des autres, des disciples du Seigneur; c'est à dire, des suiveurs disciplinés de Christ.",
          },
        ],
      },
    ],
  },
  {
    id: 3,
    title: "POSSÉDER SON HÉRITAGE",
    icon: faBookBible,
    content: [
      {
        id: 133,
        content: [
          {
            id: 13,
            type: "span",
            text: "Psaume 115 :16",
          },
          {
            id: 23,
            type: "text",
            text: "Nous croyons que dans chaque vision que Dieu donne à l'Homme sur la terre est rattachée un héritage. Nous avons la mission d'aider chaque personne à posséder son héritage.",
          },
        ],
      },
    ],
  },
  {
    id: 4,
    title: "ACCOMPLIR SA DESTINÉE",
    icon: faBookBible,
    content: [
      {
        id: 144,
        content: [
          {
            id: 14,
            type: "span",
            text: "Éphésiens 2 :10",
          },
          {
            id: 24,
            type: "text",
            text: "Chaque personne sur terre est un plan de Dieu en marche. Dieu n'appelle pas des personnes qualifiées, mais Il qualifie celles qu'Il appelle. Nous sommes là pour t'aider dans ce sens afin que tu accomplisses ta destinée.",
          },
        ],
      },
    ],
  },
];

const Home = () => {
  return (
    <>
      <Hero />
      <Features />
      <Intro
        hasImage
        image="/images/intro-thumb.png"
        title="Notre Vision"
        data={introData}
      />
      <Testimonial />
      <Blog />
      <Donation />
    </>
  );
};

export default Home;
