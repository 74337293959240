import { useState } from "react";
import Icon from "../components/common/Icon";
import FormItem from "../components/FormItem";
import Donation from "../sections/Donation";
import PageTitle from "../sections/PageTitle";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";

const defaultFormShape = [
  {
    id: 1,
    name: "nom",
    label: "Nom",
    size: 6,
    type: "text",
    required: true,
  },
  {
    id: 2,
    name: "prenom",
    label: "Prénom",
    size: 6,
    type: "text",
    required: true,
  },
  {
    id: 3,
    name: "datenaissance",
    label: "Date de naissance",
    size: 6,
    type: "date",
    required: true,
  },
  {
    id: 4,
    name: "lieunaissance",
    label: "Lieu de naissance",
    size: 6,
    type: "text",
    required: true,
  },
  {
    id: 5,
    name: "phone",
    label: "Téléphone",
    size: 6,
    type: "text",
    required: true,
  },
  {
    id: 6,
    name: "whatsapp",
    label: "WhatsApp",
    size: 6,
    type: "text",
    required: true,
  },
  {
    id: 7,
    name: "residence",
    label: "Lieu de résidence",
    size: 6,
    type: "text",
    required: true,
  },
  {
    id: 8,
    name: "situaionmatrimoniale",
    label: "Situation matrimoniale",
    size: 6,
    type: "select",
    required: true,
    options: [
      {
        id: 1,
        value: "Célibataire sans enfants",
      },
      {
        id: 2,
        value: "Célibataire avec enfants",
      },
      {
        id: 3,
        value: "Divorcé sans enfants",
      },
      {
        id: 4,
        value: "Divorcé avec enfants",
      },
      {
        id: 5,
        value: "Marié sans enfants",
      },
      {
        id: 6,
        value: "Marié avec enfants",
      },
    ],
  },
];

const defaultFormData = {
  nom: "",
  prenom: "",
  datenaissance: "",
  lieunaissance: "",
  phone: "",
  whatsapp: "",
  residence: "",
  situaionmatrimoniale: "",
  nombreenfant: "0",
  engagement: false,
};

const Conference = () => {
  const [formShape, setFormShape] = useState(defaultFormShape);
  const [formData, setFormData] = useState(defaultFormData);
  const [loading, setLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formData.engagement) {
      alert("Veuillez cocher la case pour certifier vos informations.");
      return;
    }

    setLoading(true);
    setShowSuccess(false);

    axios
      .post("/api/conferences", formData)
      .then(() => {
        setLoading(false);
        setShowSuccess(true);
        setFormData(defaultFormData);
        setFormShape(defaultFormShape);
      })
      .catch((er) => {
        console.error(er);
        setLoading(false);
        setShowSuccess(false);
      });
  };

  const handleChange = (e) => {
    const { name, value, checked } = e.target || {};

    setFormData((oldData) => ({
      ...oldData,
      [name]: name === "engagement" ? checked : value,
    }));

    if (name === "situaionmatrimoniale") {
      if (
        value === "Célibataire avec enfants" ||
        value === "Divorcé avec enfants" ||
        value === "Marié avec enfants"
      ) {
        const shapeNbrEnfant = {
          id: 9,
          name: "nombreenfant",
          label: "Nombre d'enfants",
          size: 12,
          type: "text",
          required: true,
        };
        setFormShape([...defaultFormShape, shapeNbrEnfant]);
      } else {
        setFormShape([...defaultFormShape]);
      }
    }
  };
  return (
    <>
      <PageTitle title="Conférence" page="CONAM 2023" />
      <section className="capture-area mt-60 pb-70 conference">
        <div className="row justify-content-center">
          <div className="col-lg-7">
            <div className="section-title text-center">
              <h3 className="title mb-2">
                Conférence Nationale des Aspirants au Mariage
              </h3>
              <h4 className="mb-2">CONAM 2023 - Première édition</h4>
              <h5 className="mb-3">Samedi 18 Février 2023 à ADZOPE</h5>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="conferencedesc">
                <p>
                  La préparation au mariage, à la vie conjugale et familiale est
                  d'une grande importance pour le bien de la société et de
                  l'Église. En effet, le sacrement de Mariage a une forte valeur
                  pour toute la communauté chrétienne et, en premier lieu, pour
                  les époux, eux dont la décision est telle qu'elle ne pourrait
                  être sujette à improvisation ou à choix hâtifs. À d'autres
                  époques, cette préparation pouvait compter sur l'appui de la
                  société qui reconnaissait les valeurs et les biens du mariage.
                  Sans heurts ni doutes, l'Église en protégeait la sainteté,
                  consciente du fait qu'il représentait une garantie ecclésiale,
                  puisqu'il constituait la cellule vitale de la société et du
                  Peuple de Dieu. L'appui que le mariage trouvait dans les
                  communautés chrétiennes, au moins dans celles évangélisées en
                  profondeur, était ferme, unitaire, compact. Séparations et
                  échecs du mariage étaient en général rares, et le divorce
                  était considéré comme une « plaie » sociale.
                </p>
                <p>
                  Aujourd'hui, au contraire, dans des cas qui sont loin d'être
                  l'exception, on assiste à une détérioration accentuée de la
                  famille et à une certaine corrosion des valeurs du mariage.
                  Dans nombre de pays, le taux des mariages a diminué. On se
                  marie plus tard, et le nombre des divorces et des séparations
                  augmente, même dès les premières années de la vie conjugale.
                  Tout cela porte inévitablement à une inquiétude pastorale, et
                  à la question mille fois répétée : les personnes qui se
                  marient y sont-elles réellement préparées ? Le problème de la
                  préparation au sacrement de Mariage et à la vie conjugale qui
                  en découle se présente comme une grande nécessité pastorale,
                  avant tout, certes, pour le bien des époux, mais aussi pour le
                  bien de la communauté chrétienne tout entière et pour celui de
                  la société. C'est pourquoi l'intérêt porté à la question
                  s'accroît de partout et les initiatives se multiplient pour
                  fournir des réponses appropriées et opportunes à la question
                  de la préparation au sacrement de Mariage.
                </p>
              </div>
            </div>
          </div>
          <div className="blog-comment-form-area">
            <form onSubmit={handleSubmit}>
              <div className="row">
                {formShape.map((item) => {
                  const { id } = item || {};
                  return (
                    <FormItem
                      key={id}
                      data={item}
                      formData={formData}
                      onChange={handleChange}
                    />
                  );
                })}
                <div className="col-lg-12  mt-30">
                  <input
                    type="checkbox"
                    id="engagement"
                    name="engagement"
                    onChange={handleChange}
                    checked={formData.engagement}
                    value="engagement"
                  />
                  <label htmlFor="engagement" className="ml-10">
                    Je certifie sur l'honneur l'exactitude des informations
                    fournies.
                  </label>
                </div>
                <div className="col-lg-12 submitcontainer mt-30">
                  <button
                    type="submit"
                    className="main-btn main-btn-2 main-btn-4 "
                    disabled={loading}
                  >
                    Sauvegarder
                    <Icon icon={faAngleRight} />
                  </button>
                </div>
              </div>
              {showSuccess && (
                <div className="col-lg-12  mt-30 successmessg">
                  <p>Merci, votre inscription a été enregistré.</p>
                </div>
              )}
            </form>
          </div>
        </div>
      </section>
      <Donation smallBg />
    </>
  );
};

export default Conference;
