import { Link } from "react-router-dom";
import PageTitle from "../sections/PageTitle";

const Page404 = () => {
  return (
    <>
      <PageTitle title="404" page="Page non trouvée" />
      <div className="pagenotfound">
        <h1>Opps..</h1>
        <h2>Page non trouvée</h2>
        <p>
          Retour à la page d'<Link to="/">Acceuil</Link>.
        </p>
      </div>
    </>
  );
};

export default Page404;
