import React from "react";

const MobileMoney = () => {
  return (
    <>
      <div className="paymentItem">
        <div className="paymentItemImg">
          <img src="/images/logos/mtn.jpg" alt="MTN Money" />
        </div>
        <div className="paymentContent">
          <p>MTN Money</p>
          <h2>(+225) 05 45 45 26 45</h2>
        </div>
      </div>
      <div className="paymentItem">
        <div className="paymentItemImg">
          <img src="/images/logos/orange.jpg" alt="Orange Money" />
        </div>
        <div className="paymentContent">
          <p>Orange Money</p>
          <h2>(+225) 07 99 13 20 13</h2>
        </div>
      </div>
      <div className="paymentItem">
        <div className="paymentItemImg">
          <img src="/images/logos/wave.jpg" alt="Wave" />
        </div>
        <div className="paymentContent">
          <p>Wave</p>
          <h2>(+225) 05 45 45 26 45</h2>
        </div>
      </div>
    </>
  );
};

export default MobileMoney;
