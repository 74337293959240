import BlogItem from "../components/BlogItem";
import Donation from "../sections/Donation";
import PageTitle from "../sections/PageTitle";
import axios from "axios";
import { useState, useEffect } from "react";

const Blog = () => {
  const [dataPost, setDataPost] = useState([]);

  useEffect(() => {
    axios
      .get("/api/posts")
      .then((res) => {
        const data = res?.data?.["hydra:member"];
        if (data?.length > 0) {
          setDataPost(data.reverse());
        }
      })
      .catch((e) => {
        console.error(e);
      });
  }, []);

  return (
    <>
      <PageTitle title="Blog" page="Blog d'EEAC" />
      <section className="blog-area pt-90 pb-120">
        <div className="container">
          <div className="row justify-content-center">
            {dataPost.map((post) => (
              <BlogItem key={post?.id} data={post} />
            ))}
          </div>
        </div>
      </section>
      <Donation smallBg />
    </>
  );
};

export default Blog;
