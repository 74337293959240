import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import Icon from "../components/common/Icon";

const Hero = () => {
  return (
    <section
      className="hero-area bg_cover"
      style={{ backgroundImage: 'url("/images/hero-bg2.jpg")' }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-7">
            <div className="hero-content">
              <h1 className="title">
                Bienvenue sur le site officiel de l'
                <span>Église Évangélique de l'Armée Céleste</span>
              </h1>
              <ul className="nav">
                <li>
                  <a className="main-btn page-scroll" href="#don">
                    Faire un Don <Icon icon={faAngleRight} />
                  </a>
                </li>
                <li>
                  <Link className="main-btn main-btn-2" to="/a-propos">
                    En savoir plus <Icon icon={faAngleRight} />
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-5">
            <div className="hero-thumb">
              <img src="/images/hero-thumb.png" alt="logo eeac" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
