import React from "react";

const FormItem = ({ data, formData, onChange }) => {
  const {
    size = 6,
    name,
    label,
    type = "text",
    required = false,
    options = [],
  } = data || {};

  return (
    <div className={`col-lg-${size}`}>
      <div className="input-box mb-15">
        <label htmlFor={name}>{label}</label>
        {type === "select" ? (
          <select
            id={name}
            name={name}
            required={required}
            value={formData?.[name] || ""}
            onChange={onChange}
          >
            <option value="">Choisir une option *</option>
            {options.map((item) => {
              const { id, value } = item || {};

              return (
                <option key={id} value={value}>
                  {value}
                </option>
              );
            })}
          </select>
        ) : (
          <input
            onChange={onChange}
            id={name}
            type={type}
            name={name}
            required={required}
            value={formData?.[name] || ""}
            placeholder={`${label}${required ? " *" : ""}`}
          />
        )}
      </div>
    </div>
  );
};

export default FormItem;
