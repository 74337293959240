import React from "react";
import { Link } from "react-router-dom";

const PageTitle = ({
  title = "",
  page = "",
  hasSubpage = false,
  subpage = {},
}) => {
  return (
    <section
      className="page-title-area bg_cover"
      style={{ backgroundImage: 'url("/images/page-title-bg2.jpg")' }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="page-title-item d-flex align-items-end">
              <div className="page-title">
                <h3 className="title">{title}</h3>
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">Acceuil</Link>
                    </li>
                    {hasSubpage && (
                      <li className="breadcrumb-item">
                        <Link to={subpage?.url}>{subpage?.label}</Link>
                      </li>
                    )}
                    <li className="breadcrumb-item active" aria-current="page">
                      {page}
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PageTitle;
