import React from "react";

const Transfert = () => {
  return (
    <>
      <div className="paymentItem">
        <div className="paymentItemImg">
          <img src="/images/logos/wu.jpg" alt="Western Union" />
        </div>
        <div className="paymentContent">
          <p>Western Union</p>
          <h2>
            <span>Nom: </span> NATABOU
          </h2>
          <h2>
            <span>Prénom: </span> YÉBOU BRICE MAHOUGNON
          </h2>
          <h2>
            <span>Pays: </span> CÔTE D'IVOIRE
          </h2>
        </div>
      </div>
      <div className="paymentItem">
        <div className="paymentItemImg">
          <img src="/images/logos/mgram.jpg" alt="Money Gramm" />
        </div>
        <div className="paymentContent">
          <p>Money Gram</p>
          <h2>
            <span>Nom: </span> NATABOU
          </h2>
          <h2>
            <span>Prénom: </span> YÉBOU BRICE MAHOUGNON
          </h2>
          <h2>
            <span>Pays: </span> CÔTE D'IVOIRE
          </h2>
        </div>
      </div>
    </>
  );
};

export default Transfert;
